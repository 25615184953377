<template>
  <div>
    <b-row class='mb-5' >
      <FormInput className='signature' fieldName='created_at' :value='signature.created_at' :canUpdate='false' :singleLine='true'/>
      <FormInput className='signature' fieldName='updated_at' :value='signature.updated_at' :canUpdate='false' :singleLine='true'/>
    </b-row>
    <b-row class='mb-5' >
      <FormInput className='signature' fieldName='id' :value='signature.id' :canUpdate='false'/>
    </b-row>
    <b-row class='mb-5' >
      <FormTextArea v-if='canReadField("description")' className='signature' fieldName='Description' v-model='signature.description' :canUpdate='canUpdateSignature("description")'/>
      <FormInput v-if='canReadField("path")' className='signature' fieldName='Path' v-model='signature.path' :canUpdate='canUpdateSignature("path")'/>
    </b-row>
    <b-row class='mb-5' >
      <FormInput v-if='canReadField("priority")' className='signature' fieldName='Priority' v-model='signature.priority' type='number' :canUpdate='canUpdateSignature("priority")'/>
    </b-row>
    <b-row class='mb-5' >
      <FormInput v-if='canReadField("username")' className='signature' fieldName='Username' v-model='signature.username' :canUpdate='canUpdateSignature("username")'/>
      <FormInput v-if='canReadField("password")' className='signature' fieldName='Password' v-model='signature.password' :canUpdate='canUpdateSignature("password")' type='password'/>
    </b-row>
    <b-row class='mb-5' >
      <FormInput v-if='canReadField("url")' className='signature' fieldName='URL' v-model='signature.url' :canUpdate='canUpdateSignature("url")'/>
      <FormInput v-if='canReadField("port")' className='signature' fieldName='Port' v-model='signature.port' :canUpdate='canUpdateSignature("port")'/>
    </b-row>
    <b-row class='mb-5' >
      <FormSelect v-if='canReadField("manufacturer")' className='signature' fieldName='Manufacturer' v-model='selectedManufacturer' :canUpdate='canUpdateSignature("manufacturer")' :options='manufacturers' @change='handleManufacturerChange' :required='false'/>
      <FormSelect v-if='canReadField("product") && this.products' className='signature' fieldName='Product' v-model='selectedProduct' :canUpdate='canUpdateSignature("product")' :options='products' :required='false'/>
    </b-row>
    <b-row class='mb-5' align-h="end" >
        <b-button size='xs' variant='success' @click='handleLink'>Add</b-button>
    </b-row>
    <SearchResults v-if='hasProducts' :data='signature.Products' className='signature' :fields='fields' :actions='tableActions' :dataActions='tableDataActions' @clickAction='handleUnlink'/>
  </div>
</template>

<script>
  import rest from '@/common/tools/Rest';
  import abilityTester from '@/config/abilityTester';
  import FormInput from '@/components/form/Input';
  import FormSelect from '@/components/form/Select';
  import FormTextArea from '@/components/form/TextArea';
  import SearchResults from '@/components/search/Results';
  import { mapMutations } from 'vuex';
  import {ADD_ERROR, ADD_SUCCESS} from '@/store/MutationTypes';

  export default {
    name: 'SignatureEdit',
    components:{
      FormInput,
      FormSelect,
      FormTextArea,
      SearchResults,
    },
    computed: {
      tableActions() {
        var actions = {
          unlink: {title: 'Unlink', icon: 'unlink'}
        };
        return actions;
      },
      tableDataActions() {
        var resp = [];
        for (var i = 0; i < this.signature.Products.length; i++) {
          var actions = [];
          actions.push('unlink');
          resp.push(actions);
        }
        return resp;
      }
    },
    created(){
        this.setDefaults();
        var args = {
          //'filter': 'Manufacturers.status neq INACTIVE',
        }
        rest.get('manufacturer/', 'manufacturer', args).then(manufacturers => {
          let man = Array.isArray(manufacturers) ? manufacturers : [];
          for (let m of man) {
            this.manufacturers.push({'display': m.name, 'value': m.id});
          }
        }).catch(e => {
          this.error(rest.getErrorMessage(e));
        })
    },
    data() {
      return {
        manufacturers: [],
        selectedManufacturer: null,
        products: [],
        selectedProduct: null,

        fields: [
          {key: 'acronym', label: 'Product'},
          {key: 'Action', class: 'text-center'}
        ],
      }
    },
    props: {
      signature: {}
    },
    methods: {
      ...mapMutations('messages', {
        alertSuccess: ADD_SUCCESS,
        error: ADD_ERROR
      }),
      _sortKeys(obj){
        if(typeof obj !== 'object' || Array.isArray(obj)){
          return obj;
        }
        var ordered = {};
        Object.keys(obj).sort().forEach((k) => {
          ordered[k] = this._sortKeys(obj[k]);
        });
        return ordered;
      },
      canReadField: function(field) {
        return abilityTester.can('read', 'signature', {signature_id: this.signature.id}, field);
      },
      canUpdateSignature: function(field) {
        return this.canUpdate(this.signature.id, field);
      },
      canUpdate(signature_id,field){
        return abilityTester.can('update', 'signature', {signature_id: signature_id}, field);
      },
      hasProducts() {
        return this.signature.Products != [];
      },
      save() {
        var data = JSON.parse(JSON.stringify(this.signature));
        var saveSignature = (data) => {
        this.$emit('entrypoint_wait_show','Saving...')
        rest.save('signature', 'signature', data).then(response => {
            for(var k in response){
              var val = response[k];
              this.$set(this.signature, k, val);
            }
            this.SignatureChanged();
            this.alertSuccess('Signature Saved');
          }).catch(e => {
              this.error(rest.getErrorMessage(e));
          }).finally(() => {
            this.$emit('entrypoint_wait_hide');
          })
        }

        saveSignature(data);
      },
      SignatureChanged(){
        this.$emit('UpdateSignature', this.signature);
      },
      setDefaults(){
        if(!this.signature.status){
          this.$set(this.signature,'status', this.statuses.ACTIVE.value);
        }
      },
      updateConfig(newConfig){
        this.signature['configuration_json'] = JSON.stringify(newConfig);
        this.SignatureChanged();
      },
      handleManufacturerChange(){
        this.products = [];
        var args = {
          'filter': 'Products.status neq INACTIVE',
          'loading': 'Products'
        }
        rest.get('manufacturer/' + this.selectedManufacturer, 'manufacturer', args).then(response => {
          let pro = Array.isArray(response.Products) ? response.Products : [];
          for (let p of pro) {
            this.products.push({'display': p.acronym, 'value': p.id, 'acronym': p.acronym, 'id': p.id});
          }
        }).catch(e => {
          this.error(rest.getErrorMessage(e));
        })
      },
      handleLink() {
        for (let p of this.signature.Products) {
          if (this.selectedProduct == p.id) {
            this.error("Product already associated with Signature.")
            return;
          }
        }
        rest.patch('product/' + this.selectedProduct + '/signature/' + this.signature.id + '/link').then(() => {
          for (let p of this.products) {
            if (p.id == this.selectedProduct) {
              this.signature.Products.push(p);
            }
          }
        }).catch(e => {
          this.error(rest.getErrorMessage(e));
        })
      },
      handleUnlink(_action, item, index) {
        rest.patch('product/' + item.id + '/signature/' + this.signature.id + '/unlink').then(() => {
          for (let p of this.signature.Products) {
            if (p.id == item.id) {
              this.signature.Products.splice(index, 1);
            }
          }
        }).catch(e => {
          this.error(rest.getErrorMessage(e));
        })
      },
    },
    mounted(){
    },
    watch: {
      signature: function(){
        this.setDefaults();
      }
    },
  }
</script>
